import {getFormProps, getInputProps, useForm} from '@conform-to/react'
import {parseWithZod} from '@conform-to/zod'
import type {ActionFunctionArgs, LoaderFunctionArgs} from '@remix-run/node'
import {json, redirect} from '@remix-run/node'
import {
  Form,
  Link,
  useActionData,
  useLoaderData,
  useNavigation,
} from '@remix-run/react'
import {Anchor, Button, TextField} from '@translated/babele-react'
import {HTTPError} from 'got'
import {useId} from 'react'
import {z} from 'zod'

import {login} from '~/models/auth/auth.server'
import {PasswordField} from '~/ui/PasswordField'
import {commitSession, getSession} from '~/utils/sessions.server'

const schema = z.object({
  email: z
    .string({required_error: 'Email is required'})
    .email('Email is invalid'),
  password: z
    .string({required_error: 'Password is required'})
    .min(8, 'Password must be at least 8 characters'),
})

export const loader = async ({request}: LoaderFunctionArgs) => {
  const session = await getSession(request.headers.get('Cookie'))

  const message = session.get('message')

  return json(
    {message},
    {
      headers: {
        'Set-Cookie': await commitSession(session),
      },
    },
  )
}

export const action = async ({request}: ActionFunctionArgs) => {
  const formData = await request.formData()

  const submission = await parseWithZod(formData, {
    async: true,
    schema: schema.transform(async (data, ctx) => {
      try {
        return await login(data)
      } catch (error) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            error instanceof HTTPError && error.response.statusCode == 400
              ? 'Invalid username or password'
              : 'Unexpected error',
        })
        return z.NEVER
      }
    }),
  })

  if (submission.status != 'success') {
    return json(submission.reply())
  }

  const session = await getSession(request.headers.get('Cookie'))
  session.set('token', submission.value.token)

  return redirect('/dashboard', {
    headers: {'Set-Cookie': await commitSession(session)},
  })
}

export default function Login() {
  const data = useLoaderData<typeof loader>()
  const actionData = useActionData<typeof action>()
  const navigation = useNavigation()
  const id = useId()
  const [form, {email, password}] = useForm({
    shouldRevalidate: 'onInput',
    lastResult: actionData,
    onValidate({formData}) {
      return parseWithZod(formData, {schema})
    },
    id,
  })

  return (
    <div className="grid place-items-center">
      <div className="max-w-sm w-full flex flex-col gap-12">
        <div className="flex flex-col gap-4">
          <h1 className="text-display-md font-bold">Log in to ModernMT</h1>
          <div className="text-neutral-800">
            Please put your login credentials below to access
          </div>
        </div>
        <Form method="post" {...getFormProps(form)}>
          <div className="flex flex-col gap-5">
            {data?.message && data.message.type == 'success' && (
              <div className="bg-success-100 p-4">{data.message.text}</div>
            )}
            {form.errors && (
              <div className="bg-notice-100 p-4">{form.errors[0]}</div>
            )}
            <div className="flex flex-col gap-2">
              <label className="font-medium" htmlFor={email.id}>
                Email address
              </label>
              <TextField
                {...getInputProps(email, {
                  type: 'email',
                })}
                placeholder="you@yourcompany.com"
                type="email"
                autoFocus
              />
              {email.errors && (
                <div className="text-critical-800 text-label-md">
                  {email.errors}
                </div>
              )}
            </div>
            <div className="flex flex-col gap-2">
              <label className="font-medium" htmlFor={password.id}>
                Password
              </label>
              <PasswordField
                {...getInputProps(password, {
                  type: 'password',
                })}
                data-testid="password"
                placeholder="Password (at least 8 characters)"
              />
              {password.errors && (
                <div className="text-critical-800 text-label-md">
                  {password.errors}
                </div>
              )}
            </div>
            <Anchor
              className="self-start"
              variant={{size: 'sm'}}
              render={<Link to="/login/reset-password">Forgot password?</Link>}
            />
            <Button
              variant={{mode: 'solid', tone: 'primary'}}
              type="submit"
              isLoading={navigation.state != 'idle'}
            >
              Continue
            </Button>
          </div>
        </Form>
      </div>
    </div>
  )
}
